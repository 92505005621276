import { Api } from 'common/server';

export class Agency {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    /**
     * Get the agents within the agency with bonus for the month
     * @param {String} memberId - the guid
     * @param {String} forDate - toISOString
     * @returns 
     */
    async agentsMonthlyBonus(memberId, forDate) {
        let url = `report/agent-monthly-bonus/${encodeURIComponent(memberId)}`;
        if (forDate) url += `?asOfDate=${encodeURIComponent(forDate)}`;
        return await this._api.get(url);
    }
}
