import {inject} from 'aurelia-framework';
import {Api} from 'common/server';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';

@inject(Api, I18n, Notifier)
export class SalesTools {

    intros = [];

    constructor(api, i18n, notifier) {
        this.api = api;
        this.i18n = i18n;
        this.notifier = notifier;
    }

    initialize(clear) {
        if (clear) this._clear();
        return this._loadAll();
    }

    _clear() {
        this.intros = [];
    }

    _loadAll() {
        var me = this;
        return new Promise((resolve, reject) => {
            if (me.intros.length) {
                resolve(me.intros);
                return;
            }

            me.intros = [];
            me.api.getData('sales-tools/list/' + this.i18n.currentLocale).then(salesToolsIntros => {
                me.intros = salesToolsIntros;
                resolve(me.intros);
            }).catch(error => {
                reject();
            });
        });
    }

    saveCategory(id, slug, ordering, isActive) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { slug: slug, ordering: ordering, isActive: isActive };

            if (id) {
                me.api.updateData("sales-tools/category/" + id, saveModel).then(data => {
                    me.notifier.success('sales-tools-category-update-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            } else {
                me.api.postData("sales-tools/category", saveModel).then(data => {
                    me.notifier.success('sales-tools-category-add-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteCategory(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me.api.deleteData("sales-tools/category/" + id).then(data => {
                me.notifier.success('sales-tools-category-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me.notifier.generalError();
                reject();
            });

        });
    }

    saveGroup(id, categoryId, newCategoryId, slug, ordering, isActive) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { salesToolCategoryId: categoryId, slug: slug, ordering: ordering, isActive: isActive };

            if (id) {
                me.api.updateData("sales-tools/category/" + newCategoryId + "/group/" + id, saveModel).then(data => {
                    me.notifier.success('sales-tools-groups-update-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            } else {
                me.api.postData("sales-tools/category/" + categoryId + "/group", saveModel).then(data => {
                    me.notifier.success('sales-tools-groups-add-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteGroup(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me.api.deleteData("sales-tools/group/" + id).then(data => {
                me.notifier.success('sales-tools-groups-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me.notifier.generalError();
                reject();
            });

        });
    }

    saveTool(id, groupId, newGroupId, slug, ordering, isActive) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { salesToolGroupId: groupId, slug: slug, ordering: ordering, isActive: isActive, salesToolType: 'Download' };

            if (id) {
                me.api.updateData("sales-tools/group/" + newGroupId + "/tool/" + id, saveModel).then(data => {
                    me.notifier.success('sales-tools-tools-update-successful', { slug: slug });
                    me._clear();
                    resolve(data.id);
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            } else {
                me.api.postData("sales-tools/group/" + groupId + "/tool", saveModel).then(data => {
                    me.notifier.success('sales-tools-tools-add-successful', { slug: slug });
                    me._clear();
                    resolve(data.id);
                }).catch(error => {
                    if (error.indexOf('sales-tools-error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteTool(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me.api.deleteData("sales-tools/tool/" + id).then(data => {
                me.notifier.success('sales-tools-tools-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me.notifier.generalError();
                reject();
            });

        });
    }
}
