import { Api } from 'common/server';

export class Roles {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async all() {
        return await this._api.get('security/roles');
    }
}
