import { Api } from 'common/server';
import { Config } from 'services/config';
import { Content } from 'services/content';
import { I18n } from 'common/i18n';

export class PrintTemplates {
    static inject = [Api, Config, Content, I18n];
    _api;
    _config;
    _content;
    _i18n;

    constructor(api, config, content, i18n) {
        this._api = api;
        this._config = config;
        this._content = content;
        this._i18n = i18n;
    }

    async byAboutType(printType, aboutType) {
        return await this._api.get(`print-templates/${encodeURIComponent(printType)}/${encodeURIComponent(aboutType)}`);
    }

    async filterOptions(aboutType) {
        return await this._api.get(`print-templates/${encodeURIComponent(aboutType)}/filter-options`);
    }

    async save(id, printType, aboutType, name) {
        const payload = { name, printType, aboutType };
        if (id) {
            return await this._api.put(`print-templates/${encodeURIComponent(id)}`, payload);
        } else {
            return await this._api.post('print-templates', payload);
        }
    }

    async copy(id) {
        return await this._api.post(`print-templates/${encodeURIComponent(id)}/copy`);
    }

    async saveHtml(id, html) {
        const payload = { html };
        return await this._api.put(`print-templates/${encodeURIComponent(id)}/html`, payload);
    }

    async saveVariable(namespace, code, value) {
        const response = await this._content.saveUiTerm(namespace, code, value);
        await this._i18n.reloadTranslations();
        return response;
    }


    async getHtml(key) {
        return await this._config.get(key);
    }

    async saveHeaderFooterHtml(endpoint, html) {
        const response = await this._api.post(endpoint, { html });
        await this._config.initialize();
        return response;
    }

    async setDefault(printType, aboutType, id) {
        return await this._api.put(`print-templates/${encodeURIComponent(printType)}/${encodeURIComponent(aboutType)}/default/${encodeURIComponent(id)}`);
    }

    async saveFilter(id, filter) {
        return await this._api.put(`print-templates/${encodeURIComponent(id)}/filters`, filter);
    }

    async delete(id) {
        return await this._api.delete(`print-templates/${encodeURIComponent(id)}`);
    }
}
