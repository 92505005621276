import { Api } from 'common/server';

export class Tags {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async list(tagType, referenceId = null) {
        let url = `tag/${encodeURIComponent(tagType)}`;
        if (referenceId) url += `/${encodeURIComponent(referenceId)}`;
        return await this._api.get(url);
    }

    /**
     * Assign tags to an object
     * @param {String} tagType
     * @param {Guid} referenceId 
     * @param {[String]} tags 
     * @returns 
     */
    async assign(tagType, referenceId, tags) {
        return this._api.post(`tag/${encodeURIComponent(tagType)}/${encodeURIComponent(referenceId)}`, tags);
    }
}

export const TAG_TYPE = {
    File: 'file'
};
