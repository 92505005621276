import { Api } from 'common/server';
import moment from 'moment';
import { TIME_PERIOD, REPORT_FOR } from 'common/constants';
import { PolicyStatusValueConverter } from 'resources/value-converters/policy-status';

export const SUMMARY_TYPE = {
    Personal: 'Personal',
    Team: 'Team',
    Agency: 'Agency',
    MasterAgency: 'MasterAgency'
};

export const CACHE_TYPE = {
    Personal: 'Personal',
    Downline: 'Downline',
    Team: 'Team',
    Agency: 'Agency',
    MasterAgency: 'MasterAgency',

    options: (access) => {
        const options = [];
        options.push(CACHE_TYPE.Personal);
        options.push(CACHE_TYPE.Downline);
        if (access) {
            if (access.isTeam) options.push(CACHE_TYPE.Team);
            if (access.isAgency) options.push(CACHE_TYPE.Agency);
            if (access.isMasterAgency) options.push(CACHE_TYPE.MasterAgency);
        }
        return options;
    },

    toScope: (cacheType) => {
        switch (cacheType) {
            case CACHE_TYPE.Personal: return 'personal';
            case CACHE_TYPE.Downline: return 'downline';
            case CACHE_TYPE.Team: return 'team';
            case CACHE_TYPE.Agency: return 'agency';
            case CACHE_TYPE.MasterAgency: return 'master-agency';
            default: return '';
        }
    }
};

export const CACHE_PERIOD = {
    Yesterday: 'Yesterday',
    LastYesterday: 'LastYesterday',
    CurrentWeek: 'CurrentWeek',
    LastWeek: 'LastWeek',
    LastLastWeek: 'LastLastWeek',
    CurrentMonth: 'CurrentMonth',
    LastMonth: 'LastMonth',
    CurrentYear: 'CurrentYear',
    LastYear: 'LastYear',

    options: () => [CACHE_PERIOD.Yesterday, CACHE_PERIOD.CurrentWeek, CACHE_PERIOD.LastWeek, CACHE_PERIOD.CurrentMonth, CACHE_PERIOD.CurrentYear],
};

export class ProductionService {
    static inject = [Api, PolicyStatusValueConverter];
    _api;
    _policyStatusValueConverter;

    TIME_PERIOD = TIME_PERIOD;

    constructor(api, policyStatusValueConverter) {
        this._api = api;
        this._policyStatusValueConverter = policyStatusValueConverter;
    }

    async status(memberId, cacheType = CACHE_TYPE.Personal, cachePeriod = CACHE_PERIOD.Yesterday) {
        return await this._api.get(`production/agent/${encodeURIComponent(memberId)}/status?type=${encodeURIComponent(cacheType)}&period=${encodeURIComponent(cachePeriod)}`);
    }

    async placementAndPersistency(memberId) {
        return await this._api.get(`production/agent/${encodeURIComponent(memberId)}/p-and-p`);
    }

    /**
     * 
     * @param {String} id - of the member
     * @param {Moment} date - used to calculate the time period to return
     * @param {String} timePeriod - the time period: WTD, MTD, YTD
     * @param {String} summaryType - the type of summary: Personal, Team, Agency
     * @returns 
     */
    summary(id = null, date = null, timePeriod = TIME_PERIOD.WTD, summaryType = SUMMARY_TYPE.Personal) {
        const apiDate = date ? date.format('l') : null;
        return this._api.get(`production/summary?memberId=${encodeURIComponent(id)}&date=${encodeURIComponent(apiDate)}&timePeriod=${encodeURIComponent(timePeriod)}&summaryType=${encodeURIComponent(summaryType)}`);
    }

    getPoliciesApiQs(forMemberId, primaryAgent, carrier, lineOfBusiness, statusGroup, start, end) {
        let qs = '';
        if (forMemberId) qs += '&forMemberId=' + encodeURIComponent(forMemberId);
        if (primaryAgent) qs += '&agentId=' + encodeURIComponent(primaryAgent);
        if (carrier) qs += '&carrierExternalId=' + encodeURIComponent(carrier);
        if (lineOfBusiness) qs += '&lob=' + encodeURIComponent(lineOfBusiness);
        if (statusGroup) qs += '&statusGroup=' + encodeURIComponent(statusGroup);
        if (start) qs += '&start=' + encodeURIComponent(this._qsDateValue(start));
        if (end) qs += '&end=' + encodeURIComponent(this._qsDateValue(end));
        qs += '&limitToSubmittedDaysInPast=365'; // rolling year
        return qs;
    }

    getPolicyListQs(forMemberId, primaryAgent, carrier, lineOfBusiness, statusGroup, start, end, limitToSubmittedDaysInPast) {
        let qs = '';
        if (forMemberId) qs += '&forMemberId=' + encodeURIComponent(forMemberId);
        if (primaryAgent) qs += '&agent=' + encodeURIComponent(primaryAgent);
        if (carrier) qs += '&carrier=' + encodeURIComponent(carrier);
        if (lineOfBusiness) qs += '&lob=' + encodeURIComponent(lineOfBusiness);
        if (statusGroup) qs += '&status=' + encodeURIComponent(statusGroup);
        if (start) qs += '&start=' + encodeURIComponent(this._qsDateValue(start));
        if (end) qs += '&end=' + encodeURIComponent(this._qsDateValue(end));
        if (limitToSubmittedDaysInPast) qs += "&limitToSubmittedDaysInPast=" + encodeURIComponent(limitToSubmittedDaysInPast);
        return qs;
    }

    getPolicies(forMemberId, primaryAgent, carrier, lineOfBusiness, statusGroup, start, end) {
        return new Promise((resolve, reject) => {
            let qs = this.getPoliciesApiQs(forMemberId, primaryAgent, carrier, lineOfBusiness, statusGroup, start, end);
            this._api.getData('production/policy-list?' + qs).then(data => {
                resolve(data);
            });
        });
    }

    async policy(id) {
        let url = `production/policy/${encodeURIComponent(id)}`;
        return await this._api.get(url);
    }

    async policyByNumber(policyNumber) {
        return await this._api.get(`policy/by-number/${encodeURIComponent(policyNumber)}`);
    }

    getCarriers() {
        return new Promise((resolve, reject) => {
            this._api.getData('production/carriers').then(data => {
                resolve(data);
            });
        });
    }

    async pendingAnnuities() {
        const policies = await this._api.get('production/policy/pending-annuities'); 
        policies.forEach(p => p.statusName = this._policyStatusValueConverter.toView(p.status));
        return policies;
    }

    /**
     * Search policies
     * @param {String} scope 
     * @param {[String]} status - an array of statuses to search by
     * @param {String} forMemberId
     * @param {String} dateType - Submitted, Paid, Chargeback - the date to use when searching by date
     * @param {String} start - the start date
     * @param {String} end - the end date
     * @returns {Promise<[Object]>}
     */
    async searchPolicies(scope, status, forMemberId = null, dateType, start, end) {
        const model = {
            forAgency: scope === 'agency',
            forTeam: scope === 'team',
            forMasterAgency: scope === 'master-agency',
            forDownline: scope === 'downline',
            forMemberId,
            status,
            dateType,
            start,
            end
        };
        const policies = await this._api.post('policy', model);
        policies.forEach(p => p.statusName = this._policyStatusValueConverter.toView(p.status));
        return policies;
    }

    _qsDateValue(dt) {
        let dtMoment = moment(dt, 'MM/DD/YYYY');
        if (!dtMoment.isValid()) return null;
        return dtMoment.format();
    }

    async policyStatuses() {
        return await this._api.get('production/policy-status-list');
    }

    async policySubStatuses() {
        return await this._api.get('production/policy-substatus-list');
    }

    async removeStatusOverride(id) {
        return await this._api.post(`policy/${encodeURIComponent(id)}/remove-status-override`);
    }

    async removeSubStatus(id) {
        return await this._api.post(`policy/${encodeURIComponent(id)}/remove-substatus`);
    }

    async volumeRankings(settings, asOfDate, memberId) {
        let url = 'production/volume-ranking';
        const qs = [];
        if (asOfDate) qs.push(`asOfDate=${encodeURIComponent(asOfDate)}`);
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.post(url, settings);
    }

    async premiumTypes() {
        return await this._api.get('production/premium-type');
    }

    async policySplits(policyId) {
        return await this._api.get(`policy/${policyId}/splits`);
    }

    async splitPolicy(policyId, splitWithMemberId, splitPercentage) {
        return await this._api.post(`policy/${policyId}/split`, { splitWithMemberId, splitPercentage });
    }

    /**
     * Get policy status changes for the selected date
     * @param {Moment} forDate - the moment date to get the status changes
     * @param {String||null} carrierId
     * @returns [object]
     */
    async policyStatusChanges(forDate, carrierId) {
        let url = `policy/status-changes?forDate=${forDate.toISOString()}`;
        if (carrierId) url += `&carrierId=${encodeURIComponent(carrierId)}`;
        return await this._api.get(url);
    }

    /**
     * Get the agency monthly production report
     * @returns [object]
     */
    async agencyMonthlyProduction(timePeriod) {
        const payload = { timePeriod };
        return await this._api.post('production/agency/monthly-production', payload);
    }
}
