import { Api } from 'common/server';

export class Pipelines {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async availableTemplates(memberId) {
        let url = 'pipelines/templates/available';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async pipelines(memberId) {
        let url = 'pipelines';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async createPipeline(name, description, color, showAgentProperties, code, carrierId) {
        const payload = { name, description, color, showAgentProperties, code: code || null, carrierId: carrierId || null };
        return await this._api.post(`pipelines`, payload);
    }

    async createPipelineFromTemplate(pipelineTemplateId) {
        const payload = { pipelineTemplateId };
        return await this._api.post(`pipelines`, payload);
    }

    async deletePipeline(id) {
        return await this._api.delete(`pipelines/${encodeURIComponent(id)}`);
    }

    async editPipeline(id, name, description, color, showAgentProperties, code, carrierId) {
        const payload = { name, description, color, showAgentProperties, code : code || null, carrierId: carrierId || null };
        return await this._api.put(`pipelines/${encodeURIComponent(id)}`, payload);
    }

    async copyPipeline(id, name, description, color, showAgentProperties) {
        const payload = { name, description, color, showAgentProperties };
        return await this._api.post(`pipelines/${encodeURIComponent(id)}/copy`, payload);
    }

    async setPipelineOrdering(orderedIds) {
        await this._api.post('pipelines/set-ordering', { orderedIds });
    }

    async pipeline(id) {
        return await this._api.get(`pipelines/${encodeURIComponent(id)}`);
    }

    async pipelineShares(id) {
        return await this._api.get(`pipelines/${encodeURIComponent(id)}/share`);
    }

    async sharePipeline(id, memberId, canEdit) {
        return await this._api.post(`pipelines/${encodeURIComponent(id)}/share/${encodeURIComponent(memberId)}/${encodeURIComponent(canEdit)}`);
    }

    async removePipelineShare(id, memberId) {
        return await this._api.delete(`pipelines/${encodeURIComponent(id)}/share/${encodeURIComponent(memberId)}`);
    }

    async pipelineBacklog(pipelineId) {
        return await this._api.get(`pipelines/${encodeURIComponent(pipelineId)}/members/backlog`);
    }

    async addToPipeline(pipelineId, memberId, stageId) {
        return await this._api.post(`pipelines/${encodeURIComponent(pipelineId)}/members/${encodeURIComponent(memberId)}?stageId=${encodeURIComponent(stageId)}`);
    }

    async removeFromPipeline(pipelineId, memberId) {
        return await this._api.delete(`pipelines/${encodeURIComponent(pipelineId)}/members/${encodeURIComponent(memberId)}`);
    }

    async changeStage(pipelineId, memberId, stageId) {
        if (!stageId) {
            return await this._api.delete(`pipelines/${pipelineId}/members/${memberId}/stages`);
        }
        return await this._api.put(`pipelines/${pipelineId}/members/${memberId}/stages/${stageId}`);
    }

    async membersInStage(pipelineId, stageId, archivedAgents) {
        return await this._api.get(`pipelines/${encodeURIComponent(pipelineId)}/stages/${stageId}/members?archived=${archivedAgents ? 'true' : 'false'}`);
    }

    async orderMembersInStage(pipelineId, stageId, orderedMemberIds) {
        return await this._api.post(`pipelines/${encodeURIComponent(pipelineId)}/stages/${encodeURIComponent(stageId)}/order-members`, orderedMemberIds);
    }

    async savePipelinePerson(pipelineId, memberId, metadata) {
        return await this._api.put(`pipelines/${encodeURIComponent(pipelineId)}/members/${encodeURIComponent(memberId)}/metadata`, metadata);
    }

    async addStage(pipelineId, name, expectedDaysInStage, color) {
        const payload = { name, expectedDaysInStage, color };
        return await this._api.post(`pipelines/${encodeURIComponent(pipelineId)}/stages`, payload);
    }

    async editStage(pipelineId, stageId, name, expectedDaysInStage, color) {
        const payload = { name, expectedDaysInStage, color };
        return await this._api.put(`pipelines/${encodeURIComponent(pipelineId)}/stages/${encodeURIComponent(stageId)}`, payload);
    }

    async deleteStage(pipelineId, stageId) {
        return await this._api.delete(`pipelines/${encodeURIComponent(pipelineId)}/stages/${encodeURIComponent(stageId)}`);
    }

    async setStageOrdering(pipelineId, orderedIds) {
        await this._api.post(`pipelines/${encodeURIComponent(pipelineId)}/set-stage-ordering`, { orderedIds });
    }

    async purgePipeline(pipelineId, memberId) {
        let url = `pipelines/${encodeURIComponent(pipelineId)}`;
        if (memberId) url += `/members/${encodeURIComponent(memberId)}/purge`;
        else url += '/purge';
        return await this._api.delete(url);
    }

    async restoreToPipeline(pipelineId, memberId) {
        let url = `pipelines/${encodeURIComponent(pipelineId)}/members/${encodeURIComponent(memberId)}/restore`;
        return await this._api.post(url);
    }
}
