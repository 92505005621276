import { Api } from 'common/server';

export class Sso {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async suranceBay(module, view, newWindow = true) {
        return await this._api.get(`sso/surancebay?module=${encodeURIComponent(module || 'producer_info')}&view=${encodeURIComponent(view || 'contracts')}&newWindow=${encodeURIComponent(newWindow)}`);
    }

    async suranceBayAffiliates() {
        return await this._api.get('sso/surancebay/affiliate');
    }

    async saveSuranceBayAffiliate(memberId, affiliate) {
        return await this._api.post(`sso/surancebay/affiliate/${encodeURIComponent(memberId)}/${encodeURIComponent(affiliate)}`);
    }
}
