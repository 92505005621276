import { Api } from 'common/server';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { c } from 'common/common';

export const toDoType = {
    GENERAL: 'General',
    AGENT: 'Agent',
    POLICY: 'Policy',
    LEAD: 'Lead',

    all: () => [ toDoType.GENERAL, toDoType.AGENT, toDoType.POLICY, toDoType.LEAD ],
}

export class ToDos {
    static inject = [Api, EventAggregator, Security];
    _api;
    _ea;
    _security;

    today = [];

    _handlers = [];

    constructor(api, ea, security) {
        this._api = api;
        this._ea = ea;
        this._security = security;
    }

    async initialize() {
        await this._loadToday();
    }

    dispose() {
        this.today = [];
    }

    async _loadToday() {
        if (!this._security.isAuthenticated) {
            this.dispose();
            return;
        }
        try {
            const today = await this._api.get('to-do/today');
            if (today && today.toDos.length) {
                today.toDos.forEach(t => {
                    if (t.aboutType === toDoType.AGENT) t.member = { id: t.aboutId, fullName: t.aboutName };
                    if (t.aboutType === toDoType.POLICY) t.policy = { id: t.aboutId, policyNumber: t.aboutName };
                    if (t.aboutType === toDoType.LEAD) t.lead = { id: t.aboutId, fullName: t.aboutName };
                });
            }
            this.today = today ? today.toDos ?? [] : [];
            this._ea.publish(c.EventKeys.toDos.todayUpdated);
        } catch (err) {
            this.today = [];
        }
    }

    async currentStatus(aboutType, aboutId) {
        return await this._api.get(`to-do/${encodeURIComponent(aboutType)}/status/${encodeURIComponent(aboutId)}`);
    }

    async listForMember(memberId, includeComplete, createdByMemberId) {
        let url = 'to-do';
        const qs = [];
        if (memberId) qs.push(`memberId=${encodeURIComponent(memberId)}`);
        if (includeComplete) qs.push('includeComplete=true');
        if (createdByMemberId) qs.push(`createdByMemberId=${encodeURIComponent(createdByMemberId)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }

    async list(type, aboutId, includeComplete) {
        let url = `to-do/${encodeURIComponent(type)}/${encodeURIComponent(aboutId)}`;
        if (includeComplete) url += '?includeComplete=true';
        return await this._api.get(url);
    }

    async save(id, aboutType, aboutId, assignToMemberId, title, comments, dueDate) {
        const model = { id, aboutType, aboutId, assignToMemberId, title, comments, dueDate };
        const response = await this._api.post('to-do', model);
        this._ea.publish(c.EventKeys.toDos.updated);
        this._ea.publish(c.EventKeys.toDos.toDoUpdated(aboutId));
        this._loadToday();
        return response;
    }

    async complete(id, aboutId, completed = true) {
        let url = `to-do/${encodeURIComponent(id)}/complete`;
        if (!completed) url += '?completed=false';
        const response = await this._api.put(url);
        this._ea.publish(c.EventKeys.toDos.updated);
        this._ea.publish(c.EventKeys.toDos.toDoUpdated(aboutId));
        this._loadToday();
        return response;
    }

    async delete(id, aboutId) {
        const response = await this._api.delete(`to-do/${encodeURIComponent(id)}`);
        this._ea.publish(c.EventKeys.toDos.updated);
        this._ea.publish(c.EventKeys.toDos.toDoUpdated(aboutId));
        this._loadToday();
        return response;
    }
}
