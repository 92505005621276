import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { Notifier } from 'common/ui';

export class RapidStarts {
    static inject = [Api, I18n, Notifier];
    intros = [];

    constructor(api, i18n, notifier) {
        this.api = api;
        this.i18n = i18n;
        this.notifier = notifier;
    }

    initialize(clear) {
        if (clear) this._clear();
        return this._loadAll();
    }

    _clear() {
        this.intros = [];
    }

    _loadAll() {
        var me = this;
        return new Promise((resolve, reject) => {
            if (me.intros.length) {
                resolve(me.intros);
                return;
            }

            me.intros = [];
            me.api.getData('rapid-start/list/' + this.i18n.currentLocale).then(rapidStartIntros => {
                me.intros = rapidStartIntros;
                resolve(me.intros);
            }).catch(error => {
                reject();
            });
        });
    }

    saveRapidStart(id, slug, ordering, isActive, minimumLevel) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { slug: slug, ordering: ordering, isActive: isActive, minimumLevel: minimumLevel };

            if (id) {
                me.api.updateData("rapid-start/" + id, saveModel).then(data => {
                    me.notifier.success('rapid-start-update-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('rapid-start.error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            } else {
                me.api.postData("rapid-start", saveModel).then(data => {
                    me.notifier.success('rapid-start-add-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (error.indexOf('rapid-start.error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteRapidStart(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me.api.deleteData("rapid-start/" + id).then(data => {
                me.notifier.success('rapid-start-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me.notifier.generalError();
                reject();
            });

        });
    }

    saveRapidStartTopic(id, rapidStartId, slug, ordering, isActive) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { rapidStartId: rapidStartId, slug: slug, ordering: ordering, isActive: isActive };

            if (id) {
                me.api.updateData("rapid-start/topic/" + id, saveModel).then(data => {
                    me.notifier.success('rapid-start-topic-update-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('rapid-start.error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            } else {
                me.api.postData("rapid-start/topic", saveModel).then(data => {
                    me.notifier.success('rapid-start-topic-add-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (error.indexOf('rapid-start.error') === 0) {
                        me.notifier.error(error);
                    } else {
                        me.notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteRapidStartTopic(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me.api.deleteData("rapid-start/topic/" + id).then(data => {
                me.notifier.success('rapid-start-topic-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me.notifier.generalError();
                reject();
            });

        });
    }

    getRapidStartIntro(slug) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (this.intros.length > 0) {
                resolve(me._getRapidStartIntroFromList(slug));
                return;
            }

            me._clear();
            me._loadAll().then(() => {
                resolve(me._getRapidStartIntroFromList(slug));
            }).catch(error => {
                console.log(error);
                reject(null);
            });
        });
    }

    _getRapidStartIntroFromList(slug) {
        for (var i = 0; i < this.intros.length; i++) {
            if (this.intros[i].slug !== slug) continue;
            return this.intros[i];
        }
        return null;
    }

    getChapter(chapterNumber) {
        for (var i = 0; i < this.chapters.length; i++) {
            if (this.chapters[i].number === chapterNumber) return this.chapters[i];
        }
        return null;
    }
}
