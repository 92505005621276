import { Api } from 'common/server';
import { I18n } from 'common/i18n';

export const ONBOARDING_STATUS_BREAKS = [0.5,-1.5];

export class Onboardings {
    static inject = [Api, I18n];
    _api;
    _i18n;

    STEP_COLORS = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c'];

    constructor(api, i18n) {
        this._api = api;
        this._i18n = i18n;
    }

    async myStatus() {
        return await this._api.get('onboarding');
    }

    async memberStatus(memberId) {
        return await this._api.get(`onboarding/${encodeURIComponent(memberId)}`);
    }

    async updateProgress(progressModel) {
        return await this._api.post('onboarding', progressModel);
    }

    async contractDetails() {
        return await this._api.get(`onboarding/contract-details`);
    }

    async checkContractedStatus(forMemberId) {
        const url = forMemberId
            ? `onboarding/${encodeURIComponent(forMemberId)}/check-contracted-status`
            : 'onboarding/check-contracted-status';
        return await this._api.get(url);
    }

    async backStep(progressModel) {
        return await this._api.post('onboarding/back-step', progressModel);
    }

    async reset(memberId) {
        return await this._api.post(`onboarding/${encodeURIComponent(memberId)}/reset`);
    }

    async complete(memberId) {
        return await this._api.post(`onboarding/${encodeURIComponent(memberId)}/complete`);
    }

    async teamStatus(forAgency) {
        let url = 'onboarding/team-status';
        if (forAgency === true) url += '?forAgency=true';
        const status = await this._api.get(url);
        status.countsRYG = [0, 0, 0];
        status.onboardings.forEach(o => {
            o.currentStep = this._i18n.tr(`onboarding-step-abbr-${o.currentStepCode}`);
            o.currentStepBoxStyle = `background-color:${this.stepColor(o.currentStepCode)}`;
            o.currentSubstep = this._i18n.tr(`onboarding-step-${o.currentSubstepCodeValue}`);
            o.statusColor = '';
            if (o.overUnderExpectedDays > ONBOARDING_STATUS_BREAKS[0]) {
                o.statusColor = 'g';
                status.countsRYG[2]++;
            } else if (o.overUnderExpectedDays > ONBOARDING_STATUS_BREAKS[1]) {
                o.statusColor = 'y';
                status.countsRYG[1]++;
            } else {
                o.statusColor = 'r';
                status.countsRYG[0]++;
            }
        });
        return status;
    }

    stepColor(stepCode) {
        switch (stepCode) {
            case 'select-platforms': return this.STEP_COLORS[0];
            case 'life-platform': return this.STEP_COLORS[1];
            case 'annuity-platform': return this.STEP_COLORS[2];
            case 'hma-platform': return this.STEP_COLORS[3];
            default: return '';
        }
    }
}
