import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { c } from 'common/common';
import moment from 'moment';

export class UiWidgets {
    static inject = [EventAggregator, Security, Api, I18n];
    lastUpdated = null;
    uiWidgets = [];
    //_updatesSinceTimer = null;

    constructor(eventAggregator, security, api, i18n) {
        this.eventAggregator = eventAggregator;
        this.security = security;
        this.api = api;
        this.i18n = i18n;

        //var mins = 5;
        //this._updatesSinceTimer = window.setInterval(() => { this._loadSince(); }, mins * 60 * 1000);
    }

    initialize() {
        // Do not load all, instead, lazy load in the get function
        //return this._loadAll();
    }

    async _loadAll() {
        this.uiWidgets = [];
        this.lastUpdated = moment();
        const widgets = await this.api.get('content/ui-widget/list/' + this.i18n.currentLocale);
        for (let i = 0; i < widgets.length; i++) {
            this.uiWidgets[widgets[i].key] = widgets[i];
        }
    }

    async _loadSince() {
        try {
            const widgets = await this.api.get('content/ui-widget/updated-since/' + this.i18n.currentLocale + '?sinceDate=' + this.lastUpdated.toISOString());
            for (let i = 0; i < widgets.length; i++) {
                this._updateCachedWidget(widgets[i]);
            }
            this.lastUpdated = moment();
        } catch (err) {
            console.log(err);
        }
    }

    async loadKey(key) {
        try {
            const uiWidget = await this._get(key, undefined, this.i18n.currentLocale);
            this.addToCache(uiWidget);
        } catch (err) {
            console.log(err);
        }
    }

    async get(key, memberId, lazyLoad = true) {
        if (!memberId) {
            let widget = this.uiWidgets[key];
            if (!widget) {
                widget = lazyLoad ? await this._get(key, undefined, this.i18n.currentLocale) : { key: undefined };
                if (widget.key) {
                    this.addToCache(widget);
                } else {
                    widget = { id: null, key: key, title: '', html: '' };
                }
            }
            // return a clone to not get the replaced variables
            return JSON.parse(JSON.stringify(widget));
        }

        // For memberId, always get from API
        const memberWidget = await this._get(key, memberId, null);
        return memberWidget;
    }

    async getForLocale(key, locale, memberId) {
        try {
            if (this.i18n.currentLocale === locale && !memberId) {
                return this.get(key);
            }

            const uiWidget = await this.api.get('content/ui-widget/' + key + '/' + locale);
            if (uiWidget.id === '00000000-0000-0000-0000-000000000000' || !uiWidget.id) {
                uiWidget.id = null;
                uiWidget.key = key;
                uiWidget.language = locale;
            }
            return uiWidget;
        } catch (err) {
            return null;
        }
    }

    async _get(key, memberId, locale) {
        try {
            const uiWidget = await this.api.get('content/ui-widget/' + key + '?pendingApproval=true');
            if (uiWidget.id === '00000000-0000-0000-0000-000000000000' || !uiWidget.id) {
                uiWidget.id = null;
                uiWidget.key = key;
                uiWidget.language = locale;
                if (uiWidget.pendingApproval) {
                    uiWidget.title = uiWidget.pendingApproval.title;
                    uiWidget.html = uiWidget.pendingApproval.html;
                }
            }
            return uiWidget;
        } catch (err) {
            return null;
        }
    }

    async add(key, language, title, html, widgetType, approval, memberId, comment, fromEmail) {
        const model = { key, language, title, html, widgetType, approval, memberId, comment, fromEmail: fromEmail || null };
        const data = await this.api.post('content/ui-widget', model);
        const widget = {
            id: data.id,
            key: key,
            lastUpdated: data.lastUpdated,
            language: language,
            title: title,
            html: html,
            fromEmail: fromEmail || null,
        };
        this._updateCachedWidget(widget);
        return widget;
    }

    async update(id, key, language, title, html, widgetType, approval, memberId, comment, fromEmail) {
        const model = { key, language, title, html, widgetType, approval, memberId, comment, fromEmail: fromEmail || null };
        const data = await this.api.put('content/ui-widget/' + id, model);
        const widget = {
            id: id,
            key: key,
            lastUpdated: data.lastUpdated,
            language: language,
            title: title,
            html: html,
            fromEmail: fromEmail || null,
        };
        this._updateCachedWidget(widget);
    }

    addToCache(uiWidget) {
        this._updateCachedWidget(uiWidget, false);
    }

    _updateCachedWidget(uiWidget, publishEvent = true) {
        if (!this._matchesCurrentLocale(uiWidget.language)) return;
        var existing = this.get(uiWidget.key, undefined, false);
        if (existing.id) {
            this.uiWidgets[uiWidget.key].title = uiWidget.title;
            this.uiWidgets[uiWidget.key].html = uiWidget.html;
        } else {
            this.uiWidgets[uiWidget.key] = uiWidget;
        }

        if (publishEvent) this.eventAggregator.publish(c.EventKeys.uiWidgetUpdated, { key: uiWidget.key });
    }

    _matchesCurrentLocale(locale) {
        if (locale === 'en' && this.i18n.currentLocale === 'en-US') return true;
        return locale === this.i18n.currentLocale;
    }

    async getArchives(key, language) {
        return await this.api.get('content/ui-widget/history/' + encodeURIComponent(language) + '?key=' + encodeURIComponent(key));
    }
}
