import { EventAggregator } from 'aurelia-event-aggregator';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { c } from 'common/common';
import environment from '../../config/environment.json';

export class Tasks {
    static inject = [EventAggregator, Api, Security];
    _ea;
    _api;
    _security;

    current = [];
    _checker;
    _checkEveryMinutes = environment.taskCheckMinutes;
    _errorCtr = 0;

    _handlers = [];

    constructor(ea, api, security) {
        this._ea = ea;
        this._api = api;
        this._security = security;
    }

    async initialize() {
        await this._load();
    }

    dispose() {
        this._clearChecker();
        this.current = [];
    }

    async _load() {
        if (!this._security.isAuthenticated) {
            this.dispose();
            return;
        }
        try {
            this.current = await this._api.get('task');
            this._fireEvent();
            this._errorCtr = 0;
        } catch (err) {
            this.current = [];
            this.errorCtr++;
        } finally {
            if (this._errorCtr > 10) return;
            this._checker = window.setTimeout(() => this._load(), this._checkEveryMinutes * 60 * 1000);
        }
    }

    _fireEvent() {
        this._ea.publish(c.EventKeys.tasks.updated);
    }

    _clearChecker() {
        if (!this._checker) return;
        window.clearTimeout(this._checker);
        this._checker = undefined;
    }

    async complete(taskId, model) {
        const response = await this._api.post(`task/${encodeURIComponent(taskId)}/complete`, model);
        const idx = this.current.findIndex(x => x.id === taskId);
        if (idx >= 0) this.current.splice(idx, 1);
        this._fireEvent();
        return response;
    }

    async save(taskId, model) {
        const response = await this._api.post(`task/${encodeURIComponent(taskId)}/set-metadata`, model);
        const idx = this.current.findIndex(x => x.id === taskId);
        if (idx >= 0) this.current[idx].metadata = model;
        return response;
    }
}
