import { Api } from 'common/server';

export class Products {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async typesByLOB() {
        return await this._api.get('product-type');
    }

    async list(carrierId) {
        const url = carrierId
            ? `product?carrierId=${encodeURIComponent(carrierId)}`
            : 'product';
        return await this._api.get(url);
    }

    async save(id, carrierId, name, aliases, lineOfBusiness, productType, underwriting, factors, existingPoliciesImpactOption, existingPoliciesImpactAfterDate) {
        const model = { id, carrierId, name, aliases, lineOfBusiness, productType, underwriting, factors, existingPoliciesImpactOption, existingPoliciesImpactAfterDate };
        return await this._api.post('product', model);
    }

    async delete(id, moveToProductId) {
        return await this._api.delete(`product/${encodeURIComponent(id)}`, { moveToProductId });
    }

    async defaultFactors() {
        return await this._api.get('product-factor-type-defaults');
    }

    async factorPremiumTypes() {
        return await this._api.get('product-factor-premium-type');
    }

    async addAlias(productId, alias) {
        return await this._api.post(`product/${productId}/alias`, { alias });
    }

    async removeAlias(productId, alias) {
        return await this._api.delete(`product/${productId}/alias`, { alias });
    }
}
