import {inject} from 'aurelia-framework';
import {Api} from 'common/server';
import {I18n} from 'common/i18n';
import {Notifier} from 'common/ui';

@inject(Api, I18n, Notifier)
export class QandA {

    intros = [];

    constructor(api, i18n, notifier) {
        this._api = api;
        this._i18n = i18n;
        this._notifier = notifier;
    }

    initialize(clear) {
        if (clear) this._clear();
        return this._loadAll();
    }

    _clear() {
        this.intros = [];
    }

    _loadAll() {
        var me = this;
        return new Promise((resolve, reject) => {
            if (me.intros.length) {
                resolve(me.intros);
                return;
            }

            me.intros = [];
            me._api.getData('q-and-a/list/' + this._i18n.currentLocale).then(qAndAIntros => {
                me.intros = qAndAIntros;
                resolve(me.intros);
            }).catch(error => {
                reject();
            });
        });
    }

    saveQandA(id, slug, ordering, isActive) {
        var me = this;
        return new Promise((resolve, reject) => {

            var saveModel = { slug: slug, ordering: ordering, isActive: isActive };

            if (id) {
                me._api.updateData("q-and-a/" + id, saveModel).then(data => {
                    me._notifier.success('q-and-a-update-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (typeof error === 'string' && error.indexOf('q-and-a-error') === 0) {
                        me._notifier.error(error);
                    } else {
                        me._notifier.generalError();
                    }
                    reject();
                });
            } else {
                me._api.postData("q-and-a", saveModel).then(data => {
                    me._notifier.success('q-and-a-add-successful', { slug: slug });
                    me._clear();
                    resolve();
                }).catch(error => {
                    if (error.indexOf('q-and-a-error') === 0) {
                        me._notifier.error(error);
                    } else {
                        me._notifier.generalError();
                    }
                    reject();
                });
            }
        });
    }

    deleteQandA(id) {
        var me = this;
        return new Promise((resolve, reject) => {

            me._api.deleteData("q-and-a/" + id).then(data => {
                me._notifier.success('q-and-a-delete-successful', { slug: me.slug });
                me._clear();
                resolve();
            }).catch(error => {
                me._notifier.generalError();
                reject();
            });
        });
    }

    getIntro(slug) {
        var me = this;
        return new Promise((resolve, reject) => {
            if (this.intros.length > 0) {
                resolve(me._getIntroFromList(slug));
                return;
            }

            me._clear();
            me._loadAll().then(() => {
                resolve(me._getIntroFromList(slug));
            }).catch(error => {
                console.log(error);
                reject(null);
            });
        });
    }

    _getIntroFromList(slug) {
        let intro = this.intros.find(x => x.slug === slug);
        return intro;
    }
}
