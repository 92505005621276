import { Api } from 'common/server';
import { Page } from 'common/ui';
import { ROLE } from 'common/constants';
import { AgentNumbers } from 'services/agent-numbers';

export class Carriers {
    static inject = [Api, Page, AgentNumbers];
    _api;
    _page;
    _agentNumbers;

    _carriers;

    ADMIN_ROLES = ROLE.CarrierAdmin;

    constructor(api, page, agentNumbers) {
        this._api = api;
        this._page = page;
        this._agentNumbers = agentNumbers;
    }

    async initialize() {
        await this.all();
    }

    dispose() {
        this._carriers = undefined;
    }

    clear() {
        this._carriers = undefined;
    }

    async all() {
        try {
            if (!this._carriers) {
                this._carriers = await this._api.get('carrier');
                this._carriers.forEach(carrier => {
                    carrier.linkLists = carrier.linkLists ? JSON.parse(carrier.linkLists) : [];
                    carrier.linkLists.forEach(ll => {
                        ll.links.forEach(l => {
                            if (!l.type) l.type = 'link';
                        });
                    });
                });
            }
            return this._carriers;
        } catch (err) {
            console.log(err);
        }
    }

    async forAgentSite() {
        return await this._api.get('carrier/for-agent-site');
    }

    async favorites() {
        return await this._api.get('carrier/favorites');
    }

    async add(model) {
        model.metadata = JSON.stringify(model.metadata);
        const data = await this._api.post('carrier', model);
        this._carriers = undefined;
        return data;
    }

    async update(id, model) {
        model.metadata = JSON.stringify(model.metadata);
        const data = await this._api.put(`carrier/${encodeURIComponent(id)}`, model);
        this._carriers = undefined;
        return data;
    }

    async delete(id) {
        const data = await this._api.delete(`carrier/${encodeURIComponent(id)}`);
        this._carriers = undefined;
        return data;
    }

    async policyCount(id) {
        return await this._api.get(`carrier/${encodeURIComponent(id)}/policy-count`);
    }

    async saveLinkLists(carrierId, linkLists) {
        await this._api.put(`carrier/${carrierId}/link-lists`, { linkLists: JSON.stringify(linkLists)});
    }

    async commissionGrid(id) {
        return await this._api.get(`carrier/${encodeURIComponent(id)}/commission-grid`);
    }

    async policyStatuses(id) {
        return await this._api.get(`carrier/${encodeURIComponent(id)}/policy-status`);
    }

    async savePolicyStatus(id, carrierStatus, lpfnStatus) {
        const model = { carrierStatus, status: lpfnStatus };
        return await this._api.post(`carrier/${encodeURIComponent(id)}/policy-status`, model);
    }

    async deletePolicyStatus(id, carrierStatus) {
        return await this._api.delete(`carrier/${encodeURIComponent(id)}/policy-status/${encodeURIComponent(carrierStatus)}`);
    }

    async saveSuranceBayData(id, data) {
        return await this._api.post(`carrier/${encodeURIComponent(id)}/surance-bay-data`, data);
    }

    async setOrdering(orderedIds) {
        await this._api.post('carrier/set-ordering', { orderedIds });
        this._carriers = undefined;
    }

    async setActive(id, isActive) {
        await this._api.post(`carrier/${encodeURIComponent(id)}/set-is-active/${encodeURIComponent(isActive)}`);
    }

    async contractingHierarchy(id, memberId) {
        const agents = await this._api.get(`carrier/${encodeURIComponent(id)}/contracting-hierarchy/${encodeURIComponent(memberId)}`);
        for (let a of agents) {
            for (let cn of a.carrierNumbers) {
                cn.statusColor = await this._agentNumbers.statusColor(cn);
            }
        }
        return agents;
    }

    async imports(startMoment = null, endMoment = null) {
        let url = 'carrier/imports';
        let qs = [];
        if (startMoment) qs.push(`start=${encodeURIComponent(startMoment.startOf('day').toISOString())}`);
        if (endMoment) qs.push(`end=${encodeURIComponent(endMoment.endOf('day').toISOString())}`);
        if (qs.length) url += `?${qs.join('&')}`;
        return await this._api.get(url);
    }

    async importStatus(fileName) {
        return await this._api.get(`carrier/imports?fileName=${encodeURIComponent(fileName)}`);
    }

    async checkImportStatus(fileName) {
        return await this._api.get(`carrier/import-file/status?fileName=${encodeURIComponent(fileName)}`);
    }

    async cancelImport(fileName) {
        return await this._api.post(`carrier/cancel-import-file?fileName=${encodeURIComponent(fileName)}`);
    }

    async reprocessImport(fileName, additionalData) {
        return await this._api.post(`carrier/reprocess-file?fileName=${encodeURIComponent(fileName)}`, additionalData);
    }

    async importException(exceptionId) {
        return await this._api.post(`carrier/reprocess-exception/${encodeURIComponent(exceptionId)}`);
    }

    async downloadImportFile(importFileName, dataFeedType = 'policy', carrierId = null) {
        try {
            const model = { importFileName, dataFeedType, carrierId };
            await this._page.export(this._api, 'carrier/download-import-file', model);
        } catch (err) {
            console.log(err);
        }
    }
}
