import { Api } from 'common/server';
import { formatAddress } from 'services/functions';

export class CRM {
    static inject = [Api];
    _api;

    NAV_KEY = 'lpfn.crm-nav';
    _localStorage;

    constructor(api) {
        this._api = api;
        this._localStorage = window.localStorage;
    }

    dispose() {
        this.clearNav();
    }

    async clients(search) {
        let url = '/crm/client';
        if (search) url += `?search=${encodeURIComponent(search)}`;
        return await this._api.get(url);
    }

    async client(id) {
        return await this._api.get(`/crm/client/${encodeURIComponent(id)}`);
    }

    async clientPolicies(id) {
        return await this._api.get(`/crm/client/${encodeURIComponent(id)}/policy`);
    }

    async locations(includeInactive, display, timePeriod, sortType1, sortType2, sortType3) {
        try {
            let url = `crm/locations?includeInactive=${includeInactive ? 'true' : 'false'}`;
            const qs = [];
            if (display) qs.push(`display=${encodeURIComponent(display)}`);
            if (timePeriod) qs.push(`timePeriod=${encodeURIComponent(timePeriod)}`);
            if (sortType1) qs.push(`sortType1=${encodeURIComponent(sortType1)}`);
            if (sortType2) qs.push(`sortType2=${encodeURIComponent(sortType2)}`);
            if (sortType3) qs.push(`sortType3=${encodeURIComponent(sortType3)}`);
            if (qs.length) url += `&${qs.join('&')}`;

            const locations = await this._api.get(url);
            locations.forEach(l => {
                l.formattedAddress = formatAddress(l);
            });
            return locations;
        } catch (err) {
            console.log(err);
        }
    }

    clearNav() {
        this._localStorage.removeItem(this.NAV_KEY);
    }

    setNavList(clients) {
        try {
            const ids = clients.map(x => x.id);
            this._localStorage.setItem(this.NAV_KEY, JSON.stringify(ids));
        } catch (err) {
            console.log(err);
        }
    }

    getNav(currentId) {
        try {
            const ids = JSON.parse(this._localStorage.getItem(this.NAV_KEY) || '[]');
            const currentIndex = ids.indexOf(currentId);
            if (currentIndex < 0) {
                this.clearNav();
                return { hasNav: false };
            }
            const previousId = currentIndex > 0 ? ids[currentIndex - 1] : undefined;
            const nextId = currentIndex >= 0 && currentIndex < ids.length ? ids[currentIndex + 1] : undefined;
            return {
                hasNav: previousId || nextId ? true : false,
                previousId,
                nextId
            };
        } catch (err) {
            console.log(err);
        }
    }
}
