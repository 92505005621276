import { Api } from 'common/server';

export class MemberCarriers {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async downlineDebt(memberId) {
        let url = 'member-carrier/downline-debt';
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }
}
