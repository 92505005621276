import { Api } from 'common/server';
import environment from '../../config/environment.json';
import { Capacitor } from '@capacitor/core';

export class Sites {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    title() {
        if (__lpfnTheme) return __lpfnTheme.siteTitle;
        return 'LPFN';
    }

    async theme() {
        if (typeof __lpfnTheme !== 'undefined') {
            if (__lpfnTheme) {
                if (__lpfnTheme.light) return __lpfnTheme;
            }
        }
        let hostDomain = Capacitor.isNativePlatform
            ? 'mylpfn.com'
            : window.location.host;
        let url = `site/theme?domain=${encodeURIComponent(hostDomain)}`;
        return await this._api.get(url);
    }

    async themeByDomain(domain) {
        return await this._api.get(`site/theme?domain=${encodeURIComponent(domain)}`);
    }

    async themes() {
        return await this._api.get('site/themes');
    }

    async addTheme(domain, name, email, primaryColor, secondaryColor, highlightColor, boxBackgroundColor) {
        const model = { isNew: true, name, domain, email, primaryColor, secondaryColor, highlightColor, boxBackgroundColor };
        return await this._api.post('site/themes', model);
    }

    async updateTheme(domain, name, email, primaryColor, secondaryColor, highlightColor, boxBackgroundColor) {
        const model = { isNew: false, name, domain, email, primaryColor, secondaryColor, highlightColor, boxBackgroundColor };
        return await this._api.post('site/themes', model);
    }

    async deleteTheme(domain) {
        return await this._api.delete(`site/themes?domain=${encodeURIComponent(domain)}`)
    }

    async setThemeActive(domain, isActive) {
        return await this._api.post(`site/themes/set-active/${isActive}?domain=${encodeURIComponent(domain)}`);
    }
}
