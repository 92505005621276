import environment from '../../config/environment.json';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { MemberConfig } from 'services/member-config';
import { Config } from 'services/config';

export class Nylas {
    static inject = [Security, Api, MemberConfig, Config];
    _security;
    _api;
    _memberConfig;
    _config;

    _nylasIntegration;
    schedulePageDomain;
    schedulePageBase;
    _nylasLoaded = false;
    _forMemberId;
    _forMemberNylasConfig;

    constructor(security, api, memberConfig, config) {
        this._security = security;
        this._api = api;
        this._memberConfig = memberConfig;
        this._config = config;
        this.schedulePageDomain = this._config.value('yourCalendarOnlineUrl');
        this.schedulePageBase = `${this.schedulePageDomain || 'https://schedule.yourcalendaronline.com'}#`;
    }

    loadScript() {
        try {
            const existingScript = document.getElementById('lpfn-nylas-script');
            if (existingScript) return;
            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('id', 'lpfn-nylas-script');
            scriptEl.setAttribute('type', 'text/javascript');
            scriptEl.setAttribute('src', 'https://schedule.nylas.com/schedule-editor/v1.0/schedule-editor.js');
            document.body.appendChild(scriptEl);
        } catch (err) {
            console.log(err);
        }
    }

    async _nylasConfig(forMemberId) {
        if (forMemberId && !this._security.isAuthenticatedMember(forMemberId)) {
            // Need to load the nylas config for forMemberId
            if (this._forMemberId === forMemberId && this._forMemberNylasConfig) return this._forMemberNylasConfig;
            this._forMemberId = forMemberId;
            this._forMemberNylasConfig = await this._memberConfig.jsonFor(this._forMemberId, 'nylas');
            return this._forMemberNylasConfig;
        }
        return this._memberConfig.json('nylas');
    }

    async mustReauth(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return false;
            return nylas.mustReauth;
        } catch (err) {
            console.log(err);
            return false;
        }
    }

    async authorizedEmail(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return undefined;
            return nylas.email;
        } catch (err) {
            console.log(err);
            return undefined;
        }
    }

    async email(overrideEmail, forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) {
                if (overrideEmail) return overrideEmail;
                return this._security.agent.email;
            }
            return nylas.email;
        } catch (err) {
            console.log(err);
            if (this._security.agent) return this._security.agent.email;
            return '';
        }
    }

    async accessToken(forMemberId) {
        try {
            const nylas = await this._nylasConfig(forMemberId);
            if (!nylas) return null;
            return nylas.accessToken;
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    async isAuthenticated(forMemberId) {
        return await this.accessToken(forMemberId) ? true : false;
    }

    async redirectToAuthUrl(email, from, forMemberId) {
        try {
            if (!this._nylasIntegration) {
                const nylasIntegration = await this._api.get('config/NylasIntegration');
                if (nylasIntegration == null) return;
                this._nylasIntegration = JSON.parse(nylasIntegration);
            }
            let redirectUri = `${environment.url}/#/members/integrations/nylas`;
            if (window.location.origin && window.location.origin.indexOf('imobackoffice.com') >= 0) {
                redirectUri = `${window.location.origin}/#/members/integrations/nylas`;
            }
            if (from) redirectUri += `/${from}`;
            return `https://auth.yourcalendaronline.com/oauth/authorize?client_id=${this._nylasIntegration.clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=token&scopes=calendar,contacts.read_only&login_hint=${encodeURIComponent(email)}`;
        } catch (err) {
            console.log(err);
        }
    }

    schedulerUrl(slug) {
        return `${this.schedulePageBase}${slug}`;
    }

    schedulerSlug(url) {
        let slug = url.substr(this.schedulePageBase.length);
        if (slug.indexOf('/') === 0) slug = slug.substr(1);
        return slug;
    }

    async editScheduler(forMemberId) {
        try {
            const accessToken = await this.accessToken(forMemberId);
            if (!accessToken) return;
            nylas.scheduler.show({
                pageDomain: this.schedulePageBase,
                auth: { accessToken },
                style: { tintColor: '#32325d', backgroundColor: 'white' },
                defaults: {
                    appearance: { show_autoschedule: false, show_nylas_branding: false },
                    event: {
                        duration: 10,
                    },
                },
            });
        } catch (err) {
            console.log(err);
        }
    }

    async addressBook() {
        return await this._api.get('nylas/address-book');
    }

    async saveSettings(namespaceId, accountId, sid, accessToken, provider, email) {
        const payload = { namespaceId, accountId, sid, accessToken, provider, email };
        await this._api.post('nylas/settings', payload);
        await this._memberConfig.initialize();
    }

    async resetAuth(forMemberId) {
        const response = await this._api.post('nylas/reset-auth');
        await this._memberConfig.reset();
        return response;
    }
}