import { Api } from 'common/server';

export class Policies {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async convertToLeads(policyIds) {
        const payload = { policyIds };
        return await this._api.post('policy/convert-to-leads', payload);
    }
}
