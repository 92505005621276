import {inject} from 'aurelia-framework';
import {Api} from 'common/server';
import moment from 'moment';

@inject(Api)
export class RecruitingService {
    _api;

    constructor(api) {
        this._api = api;
    }

    getSummaryQs(forMemberId, teamSummary, start, end, timePeriod) {
        const qs = [];
        if (forMemberId) qs.push(`forMemberId=${encodeURIComponent(forMemberId)}`);
        if (teamSummary === true) qs.push('teamSummary=true');
        if (teamSummary === false) qs.push('teamSummary=false');
        if (start) qs.push(`start=${encodeURIComponent(this._qsDateValue(start))}`);
        if (end) qs.push(`end=${encodeURIComponent(this._qsDateValue(end))}`);
        if (timePeriod) qs.push(`timePeriod=${encodeURIComponent(timePeriod)}`);
        return qs.join('&');
    }

    async getSummaries(forMemberId, teamSummary, start, end) {
        let qs = this.getSummaryQs(forMemberId, teamSummary, start, end);
        return this._api.get(`recruiting/summary?${qs}`);
    }

    getOnboardingSummaries(forMemberId, teamSummary, start, end) {
        return new Promise((resolve, reject) => {
            let qs = this.getSummaryQs(forMemberId, teamSummary, start, end);
            this._api.getData('recruiting/onboarding-summary?' + qs).then(data => {
                resolve(data);
            });
        });
    }

    _qsDateValue(dt) {
        let dtMoment = moment(dt, 'MM/DD/YYYY');
        if (!dtMoment.isValid()) return null;
        return dtMoment.format();
    }
}