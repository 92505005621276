import { Api } from 'common/server';
import { METRIC_TYPE, AGENT_METRIC_TYPE } from 'common/constants';
import { PolicyStatusValueConverter } from 'resources/value-converters/policy-status';

export class Reports {
    static inject = [Api, PolicyStatusValueConverter];
    _api;
    _policyStatusValueConverter

    constructor(api, policyStatusValueConverter) {
        this._api = api;
        this._policyStatusValueConverter = policyStatusValueConverter;
    }

    async metrics(metrics, asOfDate, detailsForMemberId) {
        let url = 'report/metrics';
        const qsParams = [];
        if (asOfDate) qsParams.push(`asOfDate=${encodeURIComponent(asOfDate)}`);
        if (detailsForMemberId) qsParams.push(`memberId=${encodeURIComponent(detailsForMemberId)}`);
        if (qsParams.length) url += `?${qsParams.join('&')}`;
        return await this._api.post(url, metrics);
    }

    /**
     * Get the data for the by carrier metric report
     * @param {Moment} asOfDate - the moment for the report
     * @param {String} metricType - from constants.METRIC_TYPE
     * @param {String} policyDateType - from constants.POLICY_DATE_TYPE
     * @param {String} policyStatusGroup - from constants.POLICY_STATUS_GROUP
     * @param {String} premiumType
     * @returns {Object}
     */
    async byCarrierMetrics(asOfMoment, timePeriod, metricType, policyDateType, policyStatusGroup, premiumType) {
        const query = { asOfDate: asOfMoment.format(), timePeriod, metricType, policyDateType, policyStatusGroup, premiumType };
        switch (metricType) {
            case METRIC_TYPE.Count: query.policyStatusGroup = null; query.premiumType = null; break;
            case METRIC_TYPE.PolicyStatusGroup: query.premiumType = null; break;
            case METRIC_TYPE.Premium: query.policyStatusGroup = null; break;
        }
        return await this._api.post('report/by-carrier/metrics', query);
    }

    validateByCarrierMetrics(timePeriod, metricType, policyDateType, policyStatusGroup, premiumType) {
        if (!metricType || !policyDateType || !timePeriod) return false;
        switch (metricType) {
            case METRIC_TYPE.Count: return true;
            case METRIC_TYPE.PolicyStatusGroup: return policyStatusGroup ? true : false;
            case METRIC_TYPE.Premium: return premiumType ? true : false;
        }
        return false;
    }

    /**
     * Get the data for the by agent metric report
     * @param {Moment} asOfDate - the moment for the report
     * @param {String} metricType - from constants.AGENT_METRIC_TYPE
     * @param {[String]} linesOfBusiness - from constants.LOB
     * @param {String} policyDateType - from constants.POLICY_DATE_TYPE
     * @param {String} policyStatusGroup - from constants.POLICY_STATUS_GROUP
     * @param {String} premiumType
     * @param {String} detailsForMemberId - include to retrieve the details and not the full metrics
     * @returns {Object}
     */
     async byAgentMetrics(asOfMoment, timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType, detailsForMemberId) {
        const query = { asOfDate: asOfMoment.format(), timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType };
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: query.policyStatusGroup = null; query.premiumType = null; break;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: query.premiumType = null; break;
            case AGENT_METRIC_TYPE.Premium: query.policyStatusGroup = null; break;
            case AGENT_METRIC_TYPE.Recruit: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.RegisteredRep: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.Prospect: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
        }
        const url = detailsForMemberId ? `report/by-agent/metrics/${encodeURIComponent(detailsForMemberId)}/details` : 'report/by-agent/metrics';
        return await this._api.post(url, query);
    }

    validateByAgentMetrics(timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType) {
        if (!metricType || !timePeriod) return false;
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: return linesOfBusiness ? true : false;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: return linesOfBusiness && policyStatusGroup && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Premium: return linesOfBusiness && premiumType && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Recruit: return true;
            case AGENT_METRIC_TYPE.RegisteredRep: return true;
            case AGENT_METRIC_TYPE.Prospect: return true;
        }
        return false;
    }

    /**
     * Get the data for the by agency metric report
     * @param {Moment} asOfDate - the moment for the report
     * @param {String} metricType - from constants.AGENT_METRIC_TYPE
     * @param {[String]} linesOfBusiness - from constants.LOB
     * @param {String} policyDateType - from constants.POLICY_DATE_TYPE
     * @param {String} policyStatusGroup - from constants.POLICY_STATUS_GROUP
     * @param {String} premiumType
     * @param {String} detailsForMemberId - include to retrieve the details and not the full metrics
     * @returns {Object}
     */
     async byAgencyMetrics(asOfMoment, timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType, detailsForMemberId) {
        const query = { asOfDate: asOfMoment.format(), timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType };
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: query.policyStatusGroup = null; query.premiumType = null; break;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: query.premiumType = null; break;
            case AGENT_METRIC_TYPE.Premium: query.policyStatusGroup = null; break;
            case AGENT_METRIC_TYPE.Recruit: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.RegisteredRep: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.Prospect: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
        }
        const url = detailsForMemberId ? `report/by-agency/metrics/${encodeURIComponent(detailsForMemberId)}/details` : 'report/by-agency/metrics';
        return await this._api.post(url, query);
    }

    validateByAgencyMetrics(timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType) {
        if (!metricType || !timePeriod) return false;
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: return linesOfBusiness ? true : false;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: return linesOfBusiness && policyStatusGroup && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Premium: return linesOfBusiness && premiumType && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Recruit: return true;
            case AGENT_METRIC_TYPE.RegisteredRep: return true;
            case AGENT_METRIC_TYPE.Prospect: return true;
        }
        return false;
    }

    /**
     * Get the data for the by team metric report
     * @param {Moment} asOfDate - the moment for the report
     * @param {String} metricType - from constants.AGENT_METRIC_TYPE
     * @param {[String]} linesOfBusiness - from constants.LOB
     * @param {String} policyDateType - from constants.POLICY_DATE_TYPE
     * @param {String} policyStatusGroup - from constants.POLICY_STATUS_GROUP
     * @param {String} premiumType
     * @param {String} detailsForMemberId - include to retrieve the details and not the full metrics
     * @returns {Object}
     */
     async byTeamMetrics(asOfMoment, timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType, detailsForMemberId) {
        const query = { asOfDate: asOfMoment.format(), timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType };
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: query.policyStatusGroup = null; query.premiumType = null; break;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: query.premiumType = null; break;
            case AGENT_METRIC_TYPE.Premium: query.policyStatusGroup = null; break;
            case AGENT_METRIC_TYPE.Recruit: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.RegisteredRep: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
            case AGENT_METRIC_TYPE.Prospect: query.premiumType = null; query.policyStatusGroup = null; query.linesOfBusiness = null; break;
        }
        const url = detailsForMemberId ? `report/by-team/metrics/${encodeURIComponent(detailsForMemberId)}/details` : 'report/by-team/metrics';
        return await this._api.post(url, query);
    }

    validateByTeamMetrics(timePeriod, metricType, linesOfBusiness, policyDateType, policyStatusGroup, premiumType) {
        if (!metricType || !timePeriod) return false;
        switch (metricType) {
            case AGENT_METRIC_TYPE.Count: return linesOfBusiness ? true : false;
            case AGENT_METRIC_TYPE.PolicyStatusGroup: return linesOfBusiness && policyStatusGroup && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Premium: return linesOfBusiness && premiumType && policyDateType ? true : false;
            case AGENT_METRIC_TYPE.Recruit: return true;
            case AGENT_METRIC_TYPE.RegisteredRep: return true;
            case AGENT_METRIC_TYPE.Prospect: return true;
        }
        return false;
    }

    async policies(query) {
        const data = await this._api.post('policy/report', query);
        data.policies.forEach(p => p.statusName = this._policyStatusValueConverter.toView(p.status));
        return data;
    }
}
