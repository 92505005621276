import { bindable } from 'aurelia-framework';
import { Geography } from 'services/geography';

export class UsCountySelector {
    static inject = [Element, Geography];
    _element;
    _geography;

    @bindable type = 'counties';

    selectedUsCounties = [];

    constructor(element, geography) {
        this._element = element;
        this._geography = geography;
        this._initializeCounties();
    }

    _initializeCounties() {
        this.usCountiesTopo = this._geography.topojson.usCounties(
            {
                strokeColor: 'red',
                strokeOpacity: 1,
                strokeWeight: 1,
                fillColor: 'transparent',
                fillOpacity: 'transparent',
            },
            8,
            (feature) => {
                // if the feature is selected, return null for the mouseover style so the map doesn't reset the style
                if (this.selectedUsCounties.includes(feature.getId())) return null;
                return { fillColor: 'red', fillOpacity: 0.25 };
            },
            (feature) => {
                if (this.selectedUsCounties.includes(feature.getId())) {
                    return { fillColor: 'red', fillOpacity: 0.5 };
                }
                return null;
            },
            (feature) => {
                const fips = feature.getId();
                const idx = this.selectedUsCounties.indexOf(fips);
                if (idx >= 0) this.selectedUsCounties.splice(idx, 1);
                else this.selectedUsCounties.push(fips);
            },
        );
        console.log(this.usCountiesTopox);
    }

    async selectCounties() {
        try {
            let detail;
            if (this.type === 'zips') {
                detail = await this._geography.zips(this.selectedUsCounties);
                this.zips = detail;
            } else {
                detail = await this._geography.countiesByFips(this.selectedUsCounties);
            }
            this._element.dispatchEvent(new CustomEvent('selected', { bubbles: true, detail }));
        } catch (err) {
            console.log(err);
        }
    }

    clearCounties() {
        this.selectedUsCounties = [];
        this.resetTopojsonStyles = new Date().valueOf();
        this.zips = [];
    }
}