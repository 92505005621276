import { Api } from 'common/server';

export class Contests {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async all() {
        return await this._api.get('contest');
    }

    async standings(code, memberId) {
        let url = `contest/${encodeURIComponent(code)}/standings`;
        if (memberId) url += `?memberId=${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async memberStanding(code, memberId) {
        let url = `contest/${encodeURIComponent(code)}/standings/${encodeURIComponent(memberId)}`;
        return await this._api.get(url);
    }

    async monthlyConsistency(asOfDate) {
        let url = 'contest/monthly-consistency';
        if (asOfDate) url += `?asOfDate=${encodeURIComponent(asOfDate)}`;
        return await this._api.get(url);
    }

    async awardPoints(code, memberId, pointCode) {
        let url = `contest/${encodeURIComponent(code)}/award-points/${encodeURIComponent(memberId)}?pointCode=${encodeURIComponent(pointCode)}`;
        return await this._api.post(url);
    }
}
