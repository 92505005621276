import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Api } from 'common/server';
import { I18n } from 'common/i18n';
import { c } from 'common/common'
import { ROLE } from 'common/constants';

export class Dashboards {
    static inject = [EventAggregator, Security, Api, I18n];
    _ea;
    _security;
    _api;
    _i18n;

    _dashboards;

    constructor(ea, security, api, i18n) {
        this._ea = ea;
        this._security = security;
        this._api = api;
        this._i18n = i18n;
    }

    async initialize() {
        await this.all();
    }

    dispose() {
        this._dashboards = undefined;
    }

    triggerMenuUpdate() {
        this._ea.publish(c.EventKeys.menu.reset, { menu: 'members', fireEvent: true });
    }

    async all() {
        try {
            if (!this._dashboards) {
                this._dashboards = [];
                const dashboards = await this._api.get('dashboard');

                dashboards.forEach(d => {
                    let display = false;
                    if (d.showFor && d.showFor.length) {
                        d.showFor.forEach(sf => {
                            switch (sf) {
                                case 'admin': if (this._security.isAdmin) display = true; break;
                                case 'masterAgency': if (this._security.isMasterAgency()) display = true; break;
                                case 'agency': if (this._security.isAgency()) display = true; break;
                                case 'team': if (this._security.isTeam()) display = true; break;
                                case 'agent': display = true; break;
                            }
                        });
                    } else {
                        display = true;
                    }
                    if (!d.layout || !d.layout.length) display = false;
                    if (d.canEdit) display = true;
                    if (display) this._dashboards.push(d);
                });
            }
            return this._dashboards;
        } catch (err) {
            console.log(err);
            this._dashboards = [];
        }
    }

    async byKey(key) {
        try {
            if (!this._dashboards) await this.initialize();
            if (!key) {
                const defaultDashboard = this._dashboards.find(x => x.isDefault === true);
                if (defaultDashboard) return defaultDashboard;
                this._dashboards[0].isDefault = true;
                return this._dashboards[0];
            }
            const dashboard = this._dashboards.find(x => x.key === key);
            if (!dashboard) return this._dashboards[0];
            return dashboard;
        } catch (err) {
            console.log(err);
        }
    }

    async save(key, layout, name, originalKey, triggerMenuUpdate = false) {
        let url = `dashboard/${encodeURIComponent(key)}`;
        const qs = [];
        if (name) qs.push(`name=${encodeURIComponent(name)}`);
        if (originalKey) qs.push(`originalKey=${encodeURIComponent(originalKey)}`);
        if (qs.length) url += `?${qs.join('&')}`;
        await this._api.post(url, layout);

        try {
            if (this._dashboards) {
                const existingIndex = this._dashboards.findIndex(x => x.key === key);
                if (existingIndex >= 0) {
                    this._dashboards[existingIndex].layout = layout;
                } else {
                    this._dashboards = undefined;
                    await this.initialize();
                }
            }
            if (triggerMenuUpdate) {
                this.triggerMenuUpdate();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async toggleMemberDefault(key) {
        await this._api.post(`dashboard/${encodeURIComponent(key)}/set-member-default`);
        this._dashboards = undefined;
        await this.initialize();
    }

    async delete(key) {
        await this._api.delete(`dashboard/${encodeURIComponent(key)}`);
        const existing = this._dashboards.find(x => x.key === key);
        this._dashboards = undefined;
        const trigger = existing && !existing.isPublishedDashboard;
        await this.initialize();
        if (trigger) this.triggerMenuUpdate();
    }

    async setDefault(key) {
        await this._api.post(`dashboard/${encodeURIComponent(key)}/set-default`);
        this._dashboards = undefined;
        await this.initialize();
    }

    async reset(key) {
        await this._api.post(`dashboard/${encodeURIComponent(key)}/reset`);
        this._dashboards = undefined;
        await this.initialize();
    }
}
